
















































































































































































import {Vue} from 'vue-property-decorator';
import Component from 'vue-class-component';
import { Payout } from '@/models';
import {GatewayAPI, MerchantAPI} from '@/api';
import {getBaseDomain} from "@/plugins/helpers";

@Component({
    methods: {
      GatewayAPI() {
        return GatewayAPI
      }, getBaseDomain}
})
export default class PayoutDetails extends Vue {
  public payout: Payout | null = null;

  private mounted() {
    MerchantAPI
        .payoutFind(this.$route.params.hash)
        .then((res) => this.payout = new Payout(res))
        .catch(err => alert(err));
  }
}
